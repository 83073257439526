import type { Options, Result } from './PasswordStrengthValidator.d';
import { RULES, STATES } from './PasswordStrengthValidator.enum';

export class PasswordStrengthValidator {
  private rules: RULES[];
  private rulesResults: Result[] = [];
  private options: Options;

  constructor(options?: Options) {
    this.rules = Object.values(RULES);

    const defaults: Options = {
      message: '',
      validatorData: {
        [RULES.RULE_MIN]: 8,
        [RULES.RULE_LOW_CASE]: 1,
        [RULES.RULE_UP_CASE]: 1,
        [RULES.RULE_SPEC]: 1,
        [RULES.RULE_DIGIT]: 1,
      },
    };
    this.options = Object.assign({}, defaults, options);
  }

  validate(value: string): Result[] {
    this.rulesResults = [];

    // sometimes the passwords are initialized to null instead of ''.
    // By casting these passwords we prevent them from appearing as valid.
    if (value === null) {
      value = '';
    }

    if (typeof value !== 'string') {
      return []; // other checks will throw errors or exception, so end validation here.
    }

    this.rules.forEach(rule => {
      this.validateRule(rule, value);
    });

    return this.rulesResults;
  }

  validateRule(rule: RULES, value: string): void {
    if (this.options.validatorData[rule] === 0) {
      // skip rule which have zero expected occurrence
      return;
    }

    if (rule === RULES.RULE_MIN) {
      this.validateRuleMin(value, rule);
      return;
    }

    let found = null;
    switch (rule) {
    case RULES.RULE_LOW_CASE:
      found = value.match(/[a-z]/g);
      break;
    case RULES.RULE_UP_CASE:
      found = value.match(/[A-Z]/g);
      break;
    case RULES.RULE_CASE:
      found = value.match(/[a-zA-Z]/g);
      break;
    case RULES.RULE_DIGIT:
      found = value.match(/[\d]/g);
      break;
    case RULES.RULE_SPEC:
      found = value.match(/[\W]/g);
      break;
    }

    if (!found || found.length < this.options.validatorData[rule]) {
      this.addFailRule(rule, {
        found: found ? found.length : 0,
        required: this.options.validatorData[rule],
      });
    } else {
      this.addPassRule(rule);
    }
  }

  validateRuleMin(value: string, rule: RULES.RULE_MIN) {
    if (value.length < this.options.validatorData[rule]) {
      this.addFailRule(rule, {
        found: value.length,
        required: this.options.validatorData[rule],
      });
    } else {
      this.addPassRule(rule);
    }
  }

  addFailRule(rule: RULES, values: { found: number; required: number }): void {
    this.rulesResults.push({
      result: STATES.STATE_FAILED,
      rule: rule,
      found: values.found,
      required: values.required,
    });
  }

  addPassRule(rule: RULES): void {
    this.rulesResults.push({
      result: STATES.STATE_PASSED,
      rule: rule,
    });
  }

  getRulesResults(): Result[] {
    return this.rulesResults;
  }
}

export default PasswordStrengthValidator;
