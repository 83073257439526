
import {mapBy} from '@loopia-group/utils';
import {chunk} from 'lodash-es';
import {PasswordStrengthValidator} from './PasswordStrengthValidator';
import {RULES} from './PasswordStrengthValidator.enum';
import {WsIcon} from './WsIcon.vue';
import {computed, defineComponent} from 'vue';

interface Rule {
  id: string;
  label: string;
}

const rules: Rule[] = [
  {
    id: RULES.RULE_MIN,
    label: 'wsk.ws_password_strength.rule1',
  },
  {
    id: RULES.RULE_CASE,
    label: 'wsk.ws_password_strength.rule2',
  },
  {
    id: RULES.RULE_DIGIT,
    label: 'wsk.ws_password_strength.rule3',
  },
  {
    id: RULES.RULE_SPEC,
    label: 'wsk.ws_password_strength.rule4',
  },
];


export default defineComponent({
  name: 'WsPasswordStrength',
  components: {WsIcon},
  props: {
    password: {
      type: String,
      default: '',
    },
    showError: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['valid'],
  setup(props, vm) {
    const errors: string[] = [];
    const passValidator: PasswordStrengthValidator = new PasswordStrengthValidator();

    const passValidity = computed(() => {
      return mapBy('rule', passValidator.validate(props.password));
    });

    const chunks = computed(() => {
      return chunk(rules, 2);
    });

    const isPasswordValid = computed(() => {
      const valid = !Object.values(passValidity.value).some((rule: any) => rule.result);
      vm.emit('valid', valid);

      return valid;
    });

    const isRuleValid = (ruleId: string) => {
      const rules = passValidity.value;

      if (ruleId === RULES.RULE_CASE) {
        return !(rules[RULES.RULE_UP_CASE]?.result || rules[RULES.RULE_LOW_CASE]?.result);
      } else {
        return !rules[ruleId]?.result;
      }
    };

    return {
      errors,
      passValidity,
      chunks,
      isPasswordValid,
      isRuleValid,
    };
  }
});
